import { Box, IconButton, SvgIcon, alpha } from "@mui/material";
import React from "react";
import { defaultTheme } from "./theme.tsx";
import {
  KeyboardArrowRightRounded,
  KeyboardArrowLeftRounded,
} from "@mui/icons-material";

export const NextPageArrow = ({
  onMouseEnter,
  onMouseLeave,
  onClick,
  onShadowChange,
  isOnLight,
}) => {
  return (
    <Box
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={{
        "&:hover": {
          cursor: "pointer",
        },
        ".buttonContainer": {
          transition: "all 500ms ease",
        },
        "&:hover .buttonContainer": {
          backgroundColor: isOnLight
            ? alpha("#000", 0.15)
            : alpha(defaultTheme.palette.secondary.main, 0.8),
          animation: "bounceRight 500ms ease",
          "@keyframes bounceRight": {
            "0%": { transform: "translateX(0)" },
            "40%": { transform: "translateX(6px)" },
            "60%": { transform: "translateX(3px)" },
            "100%": { transform: "translateX(0)" },
          },
        },
      }}
    >
      <IconButton
        className="buttonContainer"
        sx={{
          mt: 3,
          mb: 2,
          backgroundColor: isOnLight
            ? alpha("#000", 0.05)
            : alpha(defaultTheme.palette.secondary.main, 0.6),
        }}
        onClick={() => {
          onShadowChange(false);
          onClick();
        }}
      >
        <SvgIcon fontSize="large">
          <KeyboardArrowRightRounded
            sx={{ color: defaultTheme.palette.primary.main }}
          />
        </SvgIcon>
      </IconButton>
    </Box>
  );
};

export const PrevPageArrow = ({
  onMouseEnter,
  onMouseLeave,
  onClick,
  onShadowChange,
  isOnLight,
}) => {
  return (
    <Box
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={{
        "&:hover": {
          cursor: "pointer",
        },
        ".buttonContainer": {
          transition: "all 500ms ease",
        },
        "&:hover .buttonContainer": {
          backgroundColor: isOnLight
            ? alpha("#000", 0.15)
            : alpha(defaultTheme.palette.secondary.main, 0.8),
          animation: "bounceLeft 500ms ease",
          "@keyframes bounceLeft": {
            "0%": { transform: "translateX(0)" },
            "40%": { transform: "translateX(-6px)" },
            "60%": { transform: "translateX(-3px)" },
            "100%": { transform: "translateX(0)" },
          },
        },
      }}
    >
      <IconButton
        className="buttonContainer"
        sx={{
          mt: 3,
          mb: 2,
          backgroundColor: isOnLight
            ? alpha("#000", 0.05)
            : alpha(defaultTheme.palette.secondary.main, 0.6),
        }}
        onClick={() => {
          onShadowChange(false);
          onClick();
        }}
      >
        <SvgIcon fontSize="large">
          <KeyboardArrowLeftRounded
            sx={{ color: defaultTheme.palette.primary.main }}
          />
        </SvgIcon>
      </IconButton>
    </Box>
  );
};
