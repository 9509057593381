import React from "react";
import { Box, Typography } from "@mui/material";
import { SinglePageLayout } from "./PageLayout.tsx";
import { defaultTheme } from "./theme.tsx";

// import coverImg from "./images/cover.jpg";
import "./App.css";

export const Home = ({ isShadowShown }) => {
  return (
    <SinglePageLayout
      isTextNarrow
      isShown
      isShadowShown={isShadowShown}
      title="let's boogie!"
      imgFileName="welcome_compressed.jpg"
      shadowImgFileName="welcome_shadow_compressed.jpg"
      textPosition="flex-start"
      quote={
        <Typography variant="subtitle2">
          stephanie: food or me?
          <br />
          michael: one of the two i cannot live without.
        </Typography>
      }
    >
      <Box>
        <Box maxWidth={642} mt={4}>
          <Typography>
            come join us for some fun, food, and fancy 'fits!
          </Typography>
          <Typography
            mt={1}
            sx={{
              [defaultTheme.breakpoints.down("sm")]: { display: "none" },
            }}
          >
            use the arrow keys or page buttons to flip through the pages. hold
            the arrow key or hover the button for a few seconds for a surprise!
          </Typography>
          <Typography mt={1}>
            to jump to a page, use the menu at the top left.
          </Typography>
        </Box>
      </Box>
    </SinglePageLayout>
  );
};
