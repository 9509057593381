import React from "react";
import {
  Accordion,
  AccordionSummary,
  Divider,
  alpha,
  Box,
  Link,
  Typography,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useContext } from "react";

import { UserContext } from "./userContext.js";
import { DualPageLayout, Section } from "./PageLayout.tsx";

const HotelDescription = ({
  hotelName,
  description,
  hotelLink,
  mapEmbed,
  children
}) => {
  const [showAddress, setShowAddress] = React.useState(false);
  const containerRef = React.useRef();

  function useOutsideAlert(ref) {
    React.useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowAddress(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlert(containerRef);

  return (
    <Box ref={containerRef}>
      <Box mb={2} onClick={() => setShowAddress(!showAddress)} alignContent="end" >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
            "&:hover span": {
              fontWeight: 600,
            },
            "&:hover p": {
              fontWeight: 600,
              textDecoration: "underline",
            },
          }}
        >
          <Typography>
            <Link target="_blank" href={hotelLink}>
              {hotelName}
            </Link>
          </Typography>
          <Typography variant="caption">{description}</Typography>
        </Box>
      </Box>
      <Collapse in={showAddress}>
        {children}
        {mapEmbed}
      </Collapse>
    </Box>
  );
};

export const Travel = ({ isShadowShown }) => {
  const user = useContext(UserContext);

  return (
    <DualPageLayout
      isContentLeft
      imgFileName="travel_compressed.jpg"
      shadowImgFileName="travel_shadow_compressed.jpg"
      title="travel"
      isShadowShown={isShadowShown}
      quote={
        <Typography variant="subtitle2">
          stephanie: when someone says "merci beaucoup," you say "de rien"
          <br />
          michael: durian?????
        </Typography>
      }
    >
      <Box pl={2} textAlign="left">
        {user?.locations?.find((element) => element === "atl") ? (
          <>
            <Section title="airport" sx={{ mb: 4 }}>
              <Typography>
                hartsfield-jackson atlanta international airport (atl)
              </Typography>
            </Section>
            <Section title="accomodations" sx={{ mb: 4 }}>
              <HotelDescription
                hotelName="westin buckhead"
                hotelLink="https://www.marriott.com/en-us/hotels/atlwb-the-westin-buckhead-atlanta/overview"
                description="10 min from ceremony; 20 min from reception"
                address1="3391 peachtree rd ne"
                address2="atlanta, ga 30326"
                mapEmbed={
                  <iframe
                    title="westin"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3313.599699260409!2d-84.37006710480692!3d33.84843255945778!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f50f582677da75%3A0xee3299d0ef6dd991!2sThe%20Westin%20Buckhead%20Atlanta!5e0!3m2!1sen!2sus!4v1714027728395!5m2!1sen!2sus"
                    width="100%"
                    height="300"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                }
              >
                <Typography>Our block rate with this hotel is about $200/night for a single room. Make sure to check against the online price first! The cutoff for booking through here is September 4th.</Typography>
                <Typography>If you want to book at this rate, use the link below.</Typography>
                <Box mb={2}>
                  <Link sx={{ '&:hover': { cursor: 'pointer' } }} target="_blank" href={'https://book.passkey.com/event/50819281/owner/8650452/home'}>Westin Room Block Booking</Link>
                </Box>
              </HotelDescription>
              <HotelDescription
                hotelName="nobu hotel atlanta"
                hotelLink="https://atlanta.nobuhotels.com/"
                description="15 min from ceremony; 20 min from reception"
                address1="3520 peachtree rd ne"
                address2="atlanta, ga 30326"
                mapEmbed={
                  <iframe
                    title="nobu"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3313.3797979768724!2d-84.3660349227062!3d33.85410162816402!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f50f5166526edd%3A0x9a66edef98e3886d!2sNobu%20Hotel%20Atlanta!5e0!3m2!1sen!2sus!4v1714027643133!5m2!1sen!2sus"
                    width="100%"
                    height="300"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                }
              >
                <Typography>Our block rate with this hotel is about $280/night for a single room. Make sure to check against the online price first! The cutoff for booking through here is September 4th.</Typography>
                <Typography>If you want to book at this rate, use the link below.</Typography>
                <Box mb={2}>
                  <Link sx={{ '&:hover': { cursor: 'pointer' } }} target="_blank" href={'https://be.synxis.com/?Hotel=39082&Chain=18615&arrive=2024-10-02&depart=2024-10-08&adult=1&child=0&group=OPA'}>Nobu Room Block Booking</Link>
                </Box>
              </HotelDescription>
              <HotelDescription
                hotelName="st. regis atlanta"
                hotelLink="https://thestregis.hotels-atlanta.net/en/"
                description="10 min from ceremony; 30 min from reception"
                address1="88 w paces ferry rd nw"
                address2="atlanta, ga 30305"
                mapEmbed={
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3313.9267187811483!2d-84.38508092270654!3d33.8400004289048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5058f23f21dbd%3A0x139e7b43b7485d77!2sThe%20St.%20Regis%20Atlanta!5e0!3m2!1sen!2sus!4v1714027781462!5m2!1sen!2sus"
                    width="100%"
                    height="300"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="st. regis"
                  />
                }
              />
              <HotelDescription
                hotelName="hyatt place atlanta / buckhead"
                hotelLink="https://www.hyatt.com/en-US/hotel/georgia/hyatt-place-atlanta-buckhead/atlzb"
                description="30 min from ceremony; 10 min from reception"
                address1="3242 peachtree rd ne"
                address2="atlanta, ga 30305"
                mapEmbed={
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3313.781568442603!2d-84.37498102270648!3d33.843743328708165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f505f4f62896d1%3A0x2d6be23cb758a17e!2sHyatt%20Place%20Atlanta%2FBuckhead!5e0!3m2!1sen!2sus!4v1714027824742!5m2!1sen!2sus"
                    width="100%"
                    height="300"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="hyatt place"
                  />
                }
              />
              <HotelDescription
                hotelName="moxy atlanta midtown"
                hotelLink="https://www.marriott.com/en-us/hotels/atlox-moxy-atlanta-midtown/overview"
                description="10 min from ceremony; 30 min from reception"
                address1="48 13th St NE"
                address2="atlanta, ga 30309"
                mapEmbed={
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3316.0259774640144!2d-84.38853982270759!3d33.785827331748315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5045cab89fa87%3A0x2420675c604f10f!2sMoxy%20Atlanta%20Midtown!5e0!3m2!1sen!2sus!4v1714027870546!5m2!1sen!2sus"
                    width="100%"
                    height="300"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="moxy"
                  />
                }
              />
              <HotelDescription
                hotelName="hotel indigo atlanta midtown"
                hotelLink="https://www.ihg.com/hotelindigo/hotels/us/en/atlanta/atlfx/hoteldetail"
                description="25 min from ceremony; 16 min from reception"
                address1="683 peachtree st ne"
                address2="atlanta, ga 30308"
                mapEmbed={
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3316.515049356795!2d-84.38714692270783!3d33.7731954324106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5046f6ebd30f7%3A0xdd06b7e35311a20!2sHotel%20Indigo%20Atlanta%20Midtown%2C%20an%20IHG%20Hotel!5e0!3m2!1sen!2sus!4v1714027916015!5m2!1sen!2sus"
                    width="100%"
                    height="300"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="hotel indigo"
                  />
                }
              />
              <Typography mt={2}>
                there are also many airbnbs available in atlanta. we recommend
                looking around buckhead or midtown atlanta.
              </Typography>
            </Section>
            <Section title="transportation">
              <Typography>
                uber/lyft from the airport to buckhead is about 45 mins and
                about $60. the atl airport has many major rental car options as
                well.
              </Typography>
              <Typography mt={2}>
                please be aware that traffic in atlanta is heavy and often
                unpredictable. travel time estimates on google maps may be
                inaccurate.
              </Typography>
            </Section>
          </>
        ) : null}
        {user?.locations?.find((element) => element === "cn") ? (
          <>
            <Divider component="div" sx={{ width: '75%', my: 2, borderColor: alpha('#000', 0.2), marginTop: 3, marginBottom: 3 }} />
            <Typography variant="h1" fontSize="2rem" mb={2} >shanghai</Typography>
            <Box mb={4}>
              <Section title="airports">
                <Typography fontWeight={500}>
                  PVG - Shanghai Pudong International Airport
                </Typography>
                <Typography fontWeight={500}>
                  SHA - Shanghai Hongqiao International Airport
                </Typography>
                <Typography>
                  PVG serves most of the international flights into Shanghai,
                  but most flight searches will allow you to search for options
                  for both airports.
                </Typography>
                <Typography>
                  Both airports have subway stations attached for access into
                  the city. If you want to go from Shanghai to SHA on the subway
                  please note the line splits
                  <Link href="https://youtu.be/grrLpu4IeCM?si=v50BWsWTTBQSIb6M&t=785">
                    {" "}
                    This video{" "}
                  </Link>{" "}
                  tells you how to know which train to board
                </Typography>
              </Section>
            </Box>
            <Box mb={4}>
              <Section title="accommodations">
                <Typography style={{ marginBottom: 16 }}>
                  The dinner banquet will be at the Shanghai EDITION hotel. (estimated travel time to the
                  venue included below).
                </Typography>
                <HotelDescription
                  hotelName="renaissance shanghai yu garden hotel"
                  hotelLink="https://www.marriott.com/en-us/hotels/shasy-renaissance-shanghai-yu-garden-hotel/overview/"
                  description="1 metro stop away - 5min; 15 min taxi"
                  address1="china, shanghai, huangpu"
                  address2="四牌楼河南南路159号 邮政编码: 200010"
                  mapEmbed={
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3411.75021238875!2d121.48510081200506!3d31.22764906143751!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35b270608f683013%3A0x8ce578ca15825e07!2sRenaissance%20Shanghai%20Yu%20Garden%20Hotel!5e0!3m2!1sen!2sus!4v1717569243311!5m2!1sen!2sus" width="100%"
                      height="300"
                      style={{ border: 0 }}
                      allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                      title="renaissance shanghai yu garden hotel" />
                  }
                />
                <HotelDescription
                  hotelName="shanghai marriott marquis at city center"
                  hotelLink="https://www.marriott.com/en-us/hotels/shamc-shanghai-marriott-marquis-city-centre/overview/"
                  description="15min subway ride; 40min taxi"
                  address1="china, shanghai, huangpu, people's square"
                  address2="西藏中路555号 邮政编码: 200003"
                  mapEmbed={
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3411.4291722436747!2d121.47079371200542!3d31.23654056100183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35b27064d7cfffff%3A0xb1abe024d0379e43!2sShanghai%20Marriott%20Hotel%20City%20Centre!5e0!3m2!1sen!2sus!4v1717569520291!5m2!1sen!2sus" width="100%"
                      height="300"
                      style={{ border: 0 }}
                      allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                      title="shanghai marriott marquis at city center" />
                  }
                />
                <HotelDescription
                  hotelName="crowne plaza shanghai nanjing road"
                  hotelLink="https://www.ihg.com/crowneplaza/hotels/us/en/shanghai/shgch/hoteldetail"
                  description="15min subway ride; 15min taxi"
                  address1="china, shanghai, huangpu, people's square"
                  address2="西藏中路555号 邮政编码: 200003"
                  mapEmbed={
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3412.6479769617263!2d121.42694371200457!3d31.202772562656268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35b265407d3d15f7%3A0xaba2a804823a83c5!2sCrowne%20Plaza%20Shanghai!5e0!3m2!1sen!2sus!4v1717569650779!5m2!1sen!2sus"
                      width="100%"
                      height="300"
                      style={{ border: 0 }}
                      allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                      title="crowne plaza shanghai nanjing road" />
                  }
                />
              </Section>
            </Box>
            <Box mb={4}>
              <Typography variant="h4"></Typography>
              <Accordion style={{ backgroundColor: alpha('#FFFFFF', 0.2) }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="Travel-content"
                  id="Travel-header"
                >
                  <Typography variant="h2">
                    Important travel notes for US citizens traveling to China
                  </Typography>
                </AccordionSummary>
                <Box margin={2}>
                  <Typography>
                    You must apply for a Chinese visa before traveling to China,
                    Visa on Arrival is not offered to US citizens.
                  </Typography>
                  <Typography>
                    I highly recommend installing the Alipay app to use for
                    payment and ridehailing in China. You will need to do
                    identity verification in order to make payments. Do not
                    forget the payment pin that you set when opening the app for
                    the first time!
                  </Typography>
                  <Typography mb={2}>
                    This video is a step by step tutorial on how to setup Alipay.
                  </Typography>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/3xEM6Hkn_EY?si=Fgo19KgzB4CsgbuT&amp;start=110" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen />
                  <Typography mt={4}>
                    All Google services and most social media is blocked in
                    China, if you would like to still access them, I would
                    recommend installing a VPN.{" "}
                    <Link href="https://mullvad.net/en/download/vpn/ios">
                      mullvad
                    </Link>
                    , <Link href="https://www.astrill.com/">astrill</Link>, and{" "}
                    <Link href="https://letsvpn.world/">letsVPN</Link> are
                    traditionally recommended.
                  </Typography>
                  <Typography mt={2}>
                    To get cellular data you can use an eSIM on most newer
                    phones, <Link href="https://esimdb.com/">esimDB</Link> is
                    where you can find the cheapest eSIM vendors. Stephanie and
                    I traditionally use{" "}
                    <Link href="https://www.airalo.com/">airalo</Link> in our
                    travels.
                  </Typography>
                  <Typography><b>Important note</b>: Your phone must be fully paid off
                    and unlocked to be able to use an eSIM! installing a VPN.{" "}
                    <Link href="https://mullvad.net/en/download/vpn/ios">
                      mullvad
                    </Link>
                    , <Link href="https://www.astrill.com/">astrill</Link> and{" "}
                    <Link href="https://letsvpn.world/">letsVPN</Link> are
                    traditionally recommended
                  </Typography>
                </Box>
              </Accordion>
            </Box>
            <Box mb={4}>
              <Section title="transportation">
                <Typography>
                  Didi is the recommended ridehailing app in china, you can use
                  Didi through the Alipay app.
                </Typography>
                <Typography mb={2}>
                  The Shanghai metro is very new and has been expanded to cover
                  pretty much the whole city. This video
                  shows how to setup Alipay to pay for the metro or even a
                  Shanghai metro card in Apple wallet.
                </Typography>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/grrLpu4IeCM?si=XOzWnGELj_NMzLzc&amp;start=174" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen />
                <Typography mt={3} mb={2}>
                  There are blue bikes all around Shanghai that can be rented
                  through Alipay if you feel like getting some excercise while
                  you tour Shanghai! This video shows how to unlock the blue bikes with Alipay.
                </Typography>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/tgw9LRuCGyE?si=XInBRahe6L63Iok8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen />
              </Section>
            </Box>
          </>
        ) : null}
      </Box>
    </DualPageLayout>
  );
};
