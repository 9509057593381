import { createTheme } from "@mui/material/styles";

export const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#A52228",
    },
    secondary: {
      main: "#EEE7DF",
    },
    divider: "#F9E7C6",
    text: {
      primary: "#000000",
    },
  },
  typography: {
    fontFamily: "Libre Baskerville",
    h1: {
      fontFamily: "Libre Baskerville",
      fontSize: "2.75rem",
    },
    h2: {
      fontFamily: "Lora",
      fontSize: "1.3rem",
    },
    body1: {
      fontFamily: "EB Garamond",
      fontSize: "1.2rem",
      lineHeight: "1.7",
    },
    subtitle1: {
      fontFamily: "Crimson Pro",
      fontStyle: "italic",
      fontSize: "1.2rem",
      fontWeight: 550,
    },
    subtitle2: {
      fontFamily: "Crimson Pro",
      fontStyle: "italic",
      fontSize: "1.2rem",
      fontWeight: 450,
    },
    button: {
      textTransform: "lowercase",
    },
  },
});
