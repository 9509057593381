export const guests = {
    "michael.chen.804@gmail.com": {"email": "michael.chen.804@gmail.com", "locations":["atl"], "atlFamilyDinner": true, "guestMax": 2},
    "lilxwish@gmail.com": {"email": "lilxwish@gmail.com", "locations": ["atl", "cn"], "atlFamilyDinner": true, "atlRehearsal": true, "guestMax": 1},
    "tnbdc@yahoo.com": {"email": "tnbdc@yahoo.com", "locations": ["atl", "cn"], "atlFamilyDinner": true, "atlRehearsal": true, "guestMax": 5},
    "robinchew@yahoo.com": {"email": "robinchew@yahoo.com", "locations": ["atl", "cn"], "atlFamilyDinner": true, "atlRehearsal": true, "guestMax": 5},
    "katherine.chew@gmail.com": {"email": "katherine.chew@gmail.com", "locations": ["atl", "cn"], "atlFamilyDinner": true, "atlRehearsal": true, "guestMax": 2},
    // bridal party
    "mecklenburgw@gmail.com": {"email": "mecklenburgw@gmail.com", "locations":["atl"], "atlFamilyDinner": true, "atlRehearsal": true, "guestMax": 2},
    "kconwa21@gmail.com": {"email": "kconwa21@gmail.com", "locations":["atl"], "atlFamilyDinner": true, "atlRehearsal": true, "guestMax": 2},
    "kevinnieman@gmail.com": {"email": "kevinnieman@gmail.com", "locations":["atl"], "atlFamilyDinner": true, "atlRehearsal": true, "guestMax": 1},
    "thisisshi@gmail.com": {"email": "thisisshi@gmail.com", "locations": ["atl"], "atlFamilyDinner": true, "atlRehearsal": true, "guestMax": 2},
    "sharonzhang0@gmail.com": {"email": "sharonzhang0@gmail.com", "locations":["atl", "cn"], "atlFamilyDinner": true, "atlRehearsal": true, "guestMax": 2},
    "eweezabiff@gmail.com": {"email": "eweezabiff@gmail.com", "locations":["atl", "cn"], "atlFamilyDinner": true, "atlRehearsal": true, "guestMax": 2},
    "miyamotonumber5@gmail.com": {"email": "miyamotonumber5@gmail.com", "locations":["atl", "cn"], "atlFamilyDinner": true, "atlRehearsal": true, "guestMax": 2},
    "david@benko.uk": {"email": "david@benko.uk", "locations":["atl"], "atlFamilyDinner": true, "atlRehearsal": true, "guestMax": 2},
    "emily@benko.uk": {"email": "emily@benko.uk", "locations":["atl"], "atlFamilyDinner": true, "atlRehearsal": true, "guestMax": 2},
    // michael high school
    "rkyuan@gmail.com": {"email": "rkyuan@gmail.com", "locations": ["atl"], "guestMax": 2},
    "tiffany.li.qu@gmail.com": {"email": "tiffany.li.qu@gmail.com", "locations": ["atl"], "guestMax": 2},
    "zeng.johnathan@gmail.com": {"email": "zeng.johnathan@gmail.com", "locations": ["atl"], "guestMax": 3},
    "kyleylq@gmail.com": {"email": "kyleylq@gmail.com", "locations": ["atl"], "guestMax": 2},
    "raghav.bharg@gmail.com": {"email": "raghav.bharg@gmail.com", "locations":["atl"], "guestMax": 2},
    "oliverchn2@gmail.com": {"email": "oliverchn2@gmail.com", "locations": ["atl"], "guestMax": 2},
    "danielkernan@gmail.com": {"email": "danielkernan@gmail.com", "locations": ["atl"], "guestMax": 2},
    "centathomas@gmail.com": {"email": "centathomas@gmail.com", "locations": ["atl"],  "guestMax": 1},
    "lolhbomb@gmail.com": {"email": "lolhbomb@gmail.com", "locations":["atl"], "guestMax": 1},
    "mitchkarns@gmail.com": {"email": "mitchkarns@gmail.com", "locations":["atl"], "guestMax": 2},
    // michael college
    "clementlu@proton.me": {"email": "clementlu@proton.me", "locations": ["atl"], "guestMax": 2},
    "james.lin.pan@gmail.com": {"email": "james.lin.pan@gmail.com", "locations":["atl"],  "guestMax": 2},
    "nert2207@gmail.com": {"email": "nert2207@gmail.com", "locations": ["atl"], "guestMax": 2},
    "norie09@gmail.com": {"email": "norie09@gmail.com", "locations": ["atl"], "guestMax": 2},
    "vincentvyngo@yahoo.com": {"email": "vincentvyngo@yahoo.com", "locations":["atl"], "guestMax": 1},
    "qwang2012@gmail.com": {"email": "qwang2012@gmail.com", "locations":["atl"], "guestMax": 2},
    "bchen06@gmail.com": {"email": "bchen06@gmail.com", "locations":["atl"], "guestMax": 1},
    // stack
    "justine@mailwong.com": {"email": "justine@mailwong.com", "locations":["atl"], "guestMax": 1},
    "ken.schneider18@gmail.com": {"email": "ken.schneider18@gmail.com", "locations": ["atl"], "guestMax": 1},
    "christopher.overend@gmail.com": {"email": "christopher.overend@gmail.com", "locations": ["atl"], "guestMax": 2},
    "scott.franks.1990@gmail.com": {"email": "scott.franks.1990@gmail.com", "locations": ["atl"], "guestMax": 1},
    "thecaryzhou@gmail.com": {"email": "thecaryzhou@gmail.com", "locations": ["atl"], "guestMax": 1},
    "rybrum@gmail.com": {"email": "rybrum@gmail.com", "locations":["atl"], "guestMax": 1},
    "tina.fackett@gmail.com": {"email": "tina.fackett@gmail.com", "locations":["atl"], "guestMax": 2},
    "alucid@gmail.com": {"email": "alucid@gmail.com", "locations":["atl"], "guestMax": 2},
    "leannagong@gmail.com": {"email": "leannagong@gmail.com", "locations":["atl"], "guestMax": 2},
    // delvers
    "ludazhao@gmail.com": {"email": "ludazhao@gmail.com", "locations": ["atl"], "guestMax": 1},
    "azapata.sov@gmail.com": {"email": "azapata.sov@gmail.com", "locations":["atl"],  "guestMax": 2},
    "michaelwangsu@yahoo.com": {"email": "michaelwangsu@yahoo.com", "locations":["atl"],  "guestMax": 1},
    "hongha912@gmail.com": {"email": "hongha912@gmail.com", "locations":["atl"],  "guestMax": 2},
    "allison.tran.90@gmail.com": {"email": "allison.tran.90@gmail.com", "locations":["atl"],  "guestMax": 2},
    "brian@brian-ho.io": {"email": "brian@brian-ho.io", "locations":["atl"],  "guestMax": 2},
    "bettychend@gmail.com": {"email": "bettychend@gmail.com", "locations":["atl"],  "guestMax": 2},
    "snoweriazhang@gmail.com": {"email": "snoweriazhang@gmail.com", "locations":["atl"],  "guestMax": 2},
    "ivory.wangtongyu@gmail.com": {"email": "ivory.wangtongyu@gmail.com", "locations":["atl"],  "guestMax": 1},
    //college
    "albertsch8@gmail.com": {"email": "albertsch8@gmail.com", "locations":["atl"], "atlFamilyDinner": true, "guestMax": 2},
    "gokumd@gmail.com": {"email": "gokumd@gmail.com", "locations":["atl"], "atlFamilyDinner": true, "guestMax": 2},
    "nktasingh@gmail.com": {"email": "nktasingh@gmail.com", "locations": ["atl"], "guestMax": 2},
    "carriemok@gmail.com": {"email": "carriemok@gmail.com", "locations":["atl"],  "guestMax": 2},
    "chad.howard@proton.me": {"email": "chad.howard@proton.me", "locations":["atl"],  "guestMax": 2},
    "bianca.chad.howard@gmail.com": {"email": "bianca.chad.howard@gmail.com", "locations":["atl"],  "guestMax": 2},
    "kaitlyn.e.tobin@gmail.com": {"email": "kaitlyn.e.tobin@gmail.com", "locations":["atl"],  "guestMax": 2},
    "kangsan0108@gmail.com": {"email": "kangsan0108@gmail.com", "locations":["atl"], "atlFamilyDinner": true, "guestMax": 2},
    "juliahuuu@gmail.com": {"email": "juliahuuu@gmail.com", "locations": ["atl"], "atlFamilyDinner": true, "guestMax": 2},
    "conan.chen94@gmail.com": {"email": "conan.chen94@gmail.com", "locations":["atl"], "guestMax": 2},
    // stratmore neighbors
    "lynnli20013@gmail.com": {"email": "lynnli20013@gmail.com", "locations":["atl"],  "guestMax": 2},
    "gaoping_yu@yahoo.com": {"email": "gaoping_yu@yahoo.com", "locations":["atl"],  "guestMax": 2},
    "xg_99@yahoo.com": {"email": "xg_99@yahoo.com", "locations":["atl"],  "guestMax": 2},
    "lingliumail@yahoo.com": {"email": "lingliumail@yahoo.com", "locations":["atl"],  "guestMax": 2},
    "jennysumail@gmail.com": {"email": "jennysumail@gmail.com", "locations": ["atl"], "guestMax": 2},    "anniesumail@gmail.com": {"email": "anniesumail@gmail.com", "locations": ["atl"], "guestMax": 1},
    // schew family
    "emmelinechiew_94@hotmail.com": {"email": "emmelinechiew_94@hotmail.com", "locations": ["atl", "cn"], "atlFamilyDinner": true, "guestMax": 3},
    "mayc7802@gmail.com": {"email": "mayc7802@gmail.com", "locations": ["atl", "cn"], "atlFamilyDinner": true, "guestMax": 4},
    "clementchiew@yahoo.com": {"email": "clementchiew@yahoo.com", "locations": ["atl", "cn"], "atlFamilyDinner": true, "guestMax": 3},
    "juneling-chiew@hotmail.com": {"email": "juneling-chiew@hotmail.com", "locations": ["atl", "cn"], "atlFamilyDinner": true, "guestMax": 3},
    "chewpangkine@hotmail.com": {"email": "chewpangkine@hotmail.com", "locations": ["atl", "cn"], "atlFamilyDinner": true, "guestMax": 1},
    "jingchew32@gmail.com": {"email": "jingchew32@gmail.com", "locations": ["atl", "cn"], "atlFamilyDinner": true, "guestMax": 4},
    "elliechew@hotmail.com": {"email": "elliechew@hotmail.com", "locations": ["atl", "cn"], "atlFamilyDinner": true, "guestMax": 5},
    "edwardyong1990@hotmail.com": {"email": "edwardyong1990@hotmail.com", "locations": ["atl", "cn"], "atlFamilyDinner": true, "guestMax": 2},
    "dtruong209@yahoo.com": {"email": "Dtruong209@yahoo.com", "locations":["atl", "cn"], "atlFamilyDinner": true, "guestMax": 6},
    "ugotkim@yahoo.com": {"email": "Ugotkim@yahoo.com", "locations":["atl", "cn"], "atlFamilyDinner": true, "guestMax": 6},
    "kylietruong24@gmail.com": {"email": "Kylietruong24@gmail.com", "locations":["atl", "cn"], "atlFamilyDinner": true, "guestMax": 6},
    "kmtruong900@gmail.com": {"email": "kmtruong900@gmail.com", "locations":["atl", "cn"], "atlFamilyDinner": true, "guestMax": 6},
    "kotrue@yahoo.com": {"email": "kotrue@yahoo.com", "locations":["atl", "cn"], "atlFamilyDinner": true, "guestMax": 6},
    "duongttruong@yahoo.com": {"email": "duongttruong@yahoo.com", "locations":["atl", "cn"], "atlFamilyDinner": true, "guestMax": 4},
    "duonghonglinh@yahoo.com": {"email": "duonghonglinh@yahoo.com", "locations":["atl", "cn"], "atlFamilyDinner": true, "guestMax": 4},
    "duyettruong@hotmail.com": {"email": "duyettruong@hotmail.com", "locations":["atl"], "atlFamilyDinner": true, "guestMax": 5},
    "duy5616@live.com": {"email": "duy5616@live.com", "locations":["atl"], "atlFamilyDinner": true, "guestMax": 4},
    "dtruong2227@gmail.com": {"email": "dtruong2227@gmail.com", "locations":["atl"], "atlFamilyDinner": true, "guestMax": 4},
    "truongmien870@gmail.com": {"email": "truongmien870@gmail.com", "locations":["atl"], "atlFamilyDinner": true, "guestMax": 3},
    "trannguyen1159@yahoo.com": {"email": "trannguyen1159@yahoo.com", "locations":["atl"], "atlFamilyDinner": true, "guestMax": 3},
    "thienan.ng26@gmail.com": {"email": "thienan.ng26@gmail.com", "locations":["atl"], "atlFamilyDinner": true, "guestMax": 1},
    "mantruong2007@yahoo.com": {"email": "mantruong2007@yahoo.com", "locations":["atl"], "atlFamilyDinner": true, "guestMax": 5},
    "sontq@yahoo.com": {"email": "sontq@yahoo.com", "locations":["atl"], "atlFamilyDinner": true, "guestMax": 5},
    "dungnnguyen@gmail.com": {"email": "dungnnguyen@gmail.com", "locations":["atl"], "atlFamilyDinner": true, "guestMax": 7},
    //Le family kids
    "trianle@gmail.com": {"email": "trianle@gmail.com", "locations":["atl"],  "atlFamilyDinner": true,"guestMax": 2},
    "lealex262@gmail.com": {"email": "lealex262@gmail.com", "locations":["atl"],  "atlFamilyDinner": true,"guestMax": 2},
    "thanhan287@gmail.com": {"email": "thanhan287@gmail.com", "locations":["atl"],  "atlFamilyDinner": true,"guestMax": 2},
    "alvinhle624@gmail.com": {"email": "alvinhle624@gmail.com", "locations":["atl"],  "atlFamilyDinner": true,"guestMax": 2},
    "duyqle@yahoo.com": {"email": "duyqle@yahoo.com", "locations":["atl"], "atlFamilyDinner": true, "guestMax": 7},
    "n_son@hotmail.com": {"email": "n_son@hotmail.com", "locations":["atl"], "atlFamilyDinner": true, "guestMax": 5},
    "muunguyen@yahoo.com": {"email": "muunguyen@yahoo.com", "locations":["atl"], "atlFamilyDinner": true, "guestMax": 5},
    "lgnguyen7@gmail.com": {"email": "lgnguyen7@gmail.com", "locations":["atl"], "atlFamilyDinner": true, "guestMax": 5},
    "let488582@gmail.com": {"email": "let488582@gmail.com", "locations":["atl"], "atlFamilyDinner": true, "guestMax": 4},
    "phuongpaulle@gmail.com": {"email": "phuongPaulLe@gmail.com", "locations":["atl"], "atlFamilyDinner": true, "guestMax": 4},
    "paul@viet-nomies.com": {"email": "paul@viet-nomies.com", "locations":["atl"], "atlFamilyDinner": true, "guestMax": 4},
    "nomie@viet-nomies.com": {"email": "nomie@viet-nomies.com", "locations":["atl"], "atlFamilyDinner": true, "guestMax": 4},
    "megan.truong24@gmail.com": {"email": "megan.truong24@gmail.com", "locations":["atl"], "atlFamilyDinner": true, "guestMax": 4},
    "bella2t3@gmail.com": {"email": "bella2t3@gmail.com", "locations":["atl"], "atlFamilyDinner": true, "guestMax": 4},
    "lkhuai@yahoo.com": {"email": "lkhuai@yahoo.com", "locations": ["atl", "cn"], "atlFamilyDinner": true, "guestMax": 2},
    // schew family friends
    "ducvo2@yahoo.com": {"email": "ducvo2@yahoo.com", "locations":["atl"], "guestMax": 2},
    "joyce.yii26@gmail.com": {"email": "joyce.yii26@gmail.com", "locations":["atl"], "guestMax": 1},
    "0710aaronyii@gmail.com": {"email": "0710aaronyii@gmail.com", "locations":["atl"], "guestMax": 1},
    "ericyii0213@gmail.com": {"email": "ericyii0213@gmail.com", "locations":["atl"], "guestMax": 1},
    "julianatmi@hotmail.com": {"email": "julianatmi@hotmail.com", "locations": ["atl", "cn"], "guestMax": 4},
    "thaonguyen4875@gmail.com": {"email": "thaonguyen4875@gmail.com", "locations":["atl"], "guestMax": 2},
    "vinhphuc07@gmail.com": {"email": "vinhphuc07@gmail.com", "locations":["atl"], "guestMax": 2},    
    // godfamily
    "kitsadmin@gmail.com": {"email": "kitsadmin@gmail.com", "locations":["atl"],  "guestMax": 2},
    "toanpham85@hotmail.com": {"email": "toanpham85@hotmail.com", "locations":["atl"],  "guestMax": 3},
    "qvpham84@yahoo.com": {"email": "qvpham84@yahoo.com", "locations":["atl"],  "guestMax": 5},
    "trinhpham0123@yahoo.com": {"email": "trinhpham0123@yahoo.com", "locations":["atl"], "guestMax": 1},    "duypham389@yahoo.com": {"email": "duypham389@yahoo.com", "locations":["atl"], "guestMax": 2},
    // michael family
    "zy48098@yahoo.com": {"email": "zy48098@yahoo.com", "locations": ["atl", "cn"], "atlFamilyDinner": true, "atlRehearsal": true, "guestMax": 4},
    "tpc2395@yahoo.com": {"email": "tpc2395@yahoo.com", "locations": ["atl", "cn"], "atlFamilyDinner": true, "atlRehearsal": true, "guestMax": 4},
    "hbandor@gmail.com": {"email": "hbandor@gmail.com", "locations":["atl"],  "guestMax": 2},
    "djqguo@yahoo.com": {"email": "djqguo@yahoo.com", "locations": ["atl"], "guestMax": 2},
    "seanxhuang@yahoo.com": {"email": "seanxhuang@yahoo.com", "locations":["atl"],  "guestMax": 2},
    "donyang68@yahoo.com": {"email": "donyang68@yahoo.com", "locations": ["atl"], "atlFamilyDinner": true, "guestMax": 2},
    "dunzhang@gmail.com": {"email": "dunzhang@gmail.com", "locations": ["atl"], "guestMax": 2},
    "jy.sun1@siat.ac.cn": {"email": "jy.sun1@siat.ac.cn", "locations": ["atl", "cn"], "atlFamilyDinner": true, "guestMax": 4},
    "anniesun911@gmail.com": {"email": "anniesun911@gmail.com", "locations": ["atl", "cn"], "atlFamilyDinner": true, "guestMax": 4},
    "rs712@duke.edu": {"email": "rs712@duke.edu", "locations": ["atl", "cn"], "atlFamilyDinner": true, "guestMax": 4},
}