import React from "react";
import { Typography, Box, alpha } from "@mui/material";
import { SinglePageLayout, QuoteBorder, Corner } from "./PageLayout.tsx";
import { defaultTheme } from "./theme.tsx";

export const SeeYouSoon = ({ isShadowShown }) => (
  <>
    <SinglePageLayout
      isShown
      isShadowShown={isShadowShown}
      textPosition="flex-start"
      imgFileName={"see_ya_compressed.jpg"}
      usShadowShown={false}
      width="100%"
      shadowImgFileName={"see_ya_shadow_compressed.jpg"}
    />
    <Box
      display="flex"
      justifyContent="flex-start"
      py={6}
      ml={15}
      zIndex={2}
      alignItems="center"
      position="absolute"
      width="100%"
    >

      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        px={3}
        py={2}
        maxWidth={500}
        bgcolor={alpha(defaultTheme.palette.secondary.main, 0.85)}
        borderRadius={1}
      >
        <Box width="100%" display="flex" justifyContent="space-between">
          <Corner />
          <Box sx={{ transform: 'rotate(90deg)' }}>
            <Corner />
          </Box>
        </Box>

        <Typography variant="h1">see you soon</Typography>
        <QuoteBorder width={160} height={42} />
        <Typography variant="subtitle2">
          stephanie: why did you leave the lights on?
          <br />
          michael: i didn't want you to get lost.
        </Typography>
        <Box width="100%" display="flex" justifyContent="space-between">
          <Box sx={{ transform: 'rotate(270deg)' }}>
            <Corner />
          </Box>
          <Box sx={{ transform: 'rotate(180deg)' }}>
            <Corner />
          </Box>
        </Box>
      </Box>
    </Box>
  </>
);
