import React from "react";
import Box from "@mui/material/Box";
import { alpha } from "@mui/material";

export const Gallery = ({ isShadowShown, isPageShown }) => {
  return (
    <Box
      sx={{
        display: isPageShown ? "block" : "none",
        cursor: "pointer",
        backgroundColor: alpha("#F6EBDE", 1),
        backgroundRepeat: "repeat",
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/noise.png)`,
      }}
      onClick={() => {
        window
          .open("https://photos.app.goo.gl/Mw3eAoJPAPtmaDeL8", "_blank")
          ?.focus();
      }}
    >
      <Box display={isShadowShown ? "show" : "none"}>
        <img
          width="100%"
          height="auto"
          src={`${process.env.PUBLIC_URL}/assets/gallery_shadow_compressed.jpg`}
        />
      </Box>
      <Box display={isShadowShown ? "none" : "show"}>
        <img
          width="100%"
          height="auto"
          src={`${process.env.PUBLIC_URL}/assets/gallery_compressed.jpg`}
        />
      </Box>
    </Box>
  );
};
