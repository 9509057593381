import React from "react";
import { Box, Typography } from "@mui/material";
import { useContext } from "react";

import { UserContext } from "./userContext.js";
import { DualPageLayout, Section } from "./PageLayout.tsx";

const TimeText = ({ time, event }) => (
  <Box display="flex" justifyContent="space-between">
    <Typography display="inline-block" fontWeight={500}>
      {time}
    </Typography>
    <Typography display="inline-block" fontWeight={500}>
      {event}
    </Typography>
  </Box>
);

export const Schedule = ({ isShadowShown }) => {
  const user = useContext(UserContext);
  const isPreweddingActivities =
    user["atlRehearsal"] || user["atlFamilyDinner"];
  return (
    <DualPageLayout
      isShadowShown={isShadowShown}
      imgFileName="schedule_compressed.jpg"
      shadowImgFileName="schedule_shadow_compressed.jpg"
      isContentLeft={false}
      title="schedule"
      quote={
        <Typography variant="subtitle2">
          stephanie: i want a cat.
          <br />
          michael: you can have a car instead. very similar.
        </Typography>
      }
    >
      <Box width="100%" height="100%" display="block" textAlign="left">
        {isPreweddingActivities ? (
          <Typography textAlign="center" fontWeight={500}>
            october 4, 2024
          </Typography>
        ) : null}
        {user["atlRehearsal"] ? (
          <Section title="rehearsal">
            <Typography>cathedral of christ the king</Typography>
            <Typography>2699 peachtree rd</Typography>
            <Typography>atlanta, georgia 30305</Typography>
            <Typography fontWeight={500}>6:00 pm</Typography>
          </Section>
        ) : null}
        {user["atlFamilyDinner"] ? (
          <Box mt={user["atlRehearsal"] ? 3 : 0}>
            <Section title="family dinner">
              <Typography>4643 Caroline Walk</Typography>
              <Typography>Dunwoody, GA 30338</Typography>
              <TimeText time="7:00 pm" event="Dinner starts" />
            </Section>
          </Box>
        ) : null}
        {user?.locations?.find((element) => element === "atl") ? (
          <>
            {user["atlRehearsal"] || user["atlFamilyDinner"] ? (
              <Typography
                textAlign="center"
                fontWeight={500}
                mt={isPreweddingActivities ? 5 : 0}
              >
                october 5, 2024
              </Typography>
            ) : null}
            <Section title="ceremony">
              <Typography>cathedral of christ the king</Typography>
              <Typography>2699 peachtree rd</Typography>
              <Typography>atlanta, georgia 30305</Typography>
              <TimeText time="2:00 pm" event="church ceremony" />
            </Section>
            <Box mt={3}>
              <Section title="reception">
                <Typography>callanwolde fine arts center</Typography>
                <Typography>980 briarcliff rd ne</Typography>
                <Typography>atlanta, georgia 30306</Typography>
                <TimeText
                  time="5:00 pm"
                  event="cocktail hour / tea ceremony / mời trà"
                />
                <TimeText time="6:00 pm" event="reception (partyyyyyyy)" />
              </Section>
            </Box>
            <Typography mt={2}>formal attire</Typography>
            <Typography textAlign="center" fontWeight={500}>
            october 6, 2024
          </Typography>
            <Section title="Day after gathering">
              <Typography>4643 Caroline Walk</Typography>
              <Typography>Dunwoody, GA 30338</Typography>
              <TimeText time="1:00 pm" event="picnic" />
            </Section>
          </>
        ) : null}
        {user?.locations?.find((element) => element === "cn") ? (
          <>
            <Typography textAlign="center" fontWeight={500}>
              october 19, 2024
            </Typography>
            <Section title="shanghai family reception">
              <Typography>shanghai edition</Typography>
              <Typography>199 nanjing road east</Typography>
              <Typography>huangpu, shanghai, china</Typography>
              <Typography fontWeight={500}>5:00 pm</Typography>
              <Typography mt={2}>cocktail attire</Typography>
            </Section>
          </>
        ) : null}
      </Box>
    </DualPageLayout>
  );
};
