import React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import useKey from "react-use/lib/useKey";

import { UserContext } from "./userContext.js";
import "./App.css";
import { Home } from "./Home";
import { alpha } from "@mui/material";
import { Schedule } from "./Schedule";
import { Travel } from "./Travel";
import { Login } from "./Login/Login";
import { Rsvp } from "./Rsvp";
import { ThingsToDo } from "./ThingsToDo";
import { Registry } from "./Registry";
import { Gallery } from "./Gallery.tsx";
import { SeeYouSoon } from "./SeeYouSoon";
import { NextPageArrow, PrevPageArrow } from "./utils.tsx";
import { Borders } from "./PageLayout.tsx";
import { defaultTheme } from "./theme.tsx";
import {
  AttractionsRounded,
  CardGiftcardRounded,
  CollectionsRounded,
  DraftsRounded,
  EventNoteRounded,
  FlightTakeoffRounded,
  HomeRounded,
  KeyboardDoubleArrowDownRounded,
} from "@mui/icons-material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Typography from "@mui/material/Typography";

import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";

const pages = {
  login: {
    component: Login,
    nextPage: "home",
    prevPage: "",
  },
  home: {
    component: Home,
    nextPage: "schedule",
    prevPage: "",
  },
  schedule: {
    component: Schedule,
    nextPage: "rsvp",
    prevPage: "home",
  },
  rsvp: {
    component: Rsvp,
    nextPage: "travel",
    prevPage: "schedule",
  },
  travel: {
    component: Travel,
    nextPage: "thingstodo",
    prevPage: "rsvp",
  },
  thingstodo: {
    component: ThingsToDo,
    nextPage: "registry",
    prevPage: "travel",
  },
  registry: {
    component: Registry,
    nextPage: "gallery",
    prevPage: "thingstodo",
  },
  gallery: {
    component: Gallery,
    nextPage: "seeyousoon",
    prevPage: "registry",
  },
  seeyousoon: {
    component: SeeYouSoon,
    nextPage: "",
    prevPage: "gallery",
  },
};

const imgList = [
  "./assets/welcome_compressed.jpg",
  "./assets/welcome_shadow_compressed.jpg",
  "./assets/schedule_compressed_shadow.jpg",
  "./assets/schedule_compressed.jpg",
  "./assets/thank_you_compressed.jpg",
  "./assets/thank_you_shadow_compressed.jpg",
  "./assets/rsvp_compressed.jpg",
  "./assets/rsvp_shadow_compressed.jpg",
  "./assets/travel_compressed.jpg",
  "./assets/travel_shadow_compressed.jpg",
  "./assets/thingstodo_compressed.jpg",
  "./assets/thingstodo_shadow_compressed.jpg",
  "./assets/see_ya_compressed.jpg",
  "./assets/see_ya_shadow_compressed.jpg",
  "./assets/gallery_compressed.png",
  "./assets/gallery_shadow_compressed.png",
  "./assets/registry_compressed.jpg",
  "./assets/registry_shadow_compressed.jpg",
];

const StyledListButton = ({ children, onClick, isCurrPage }) => {
  return (
    <ListItem disablePadding>
      <ListItemButton
        disabled={isCurrPage}
        divider={isCurrPage}
        onClick={onClick}
        sx={{
          "&:hover::after": {
            width: "100%",
            left: 0,
          },
          "&::after": {
            background: defaultTheme.palette.primary.main,
            bottom: 0,
            content: '""',
            display: "block",
            height: "2px",
            left: "50%",
            position: "absolute",
            transition: "width 0.3s ease 0s, left 0.3s ease 0s",
            width: 0,
          },
          "& p": {
            fontFamily: "Libre Baskerville",
          },
        }}
      >
        {children}
      </ListItemButton>
    </ListItem>
  );
};

function App() {
  const [currPage, setPage] = React.useState("login");
  const [user, setUser] = React.useState({});
  const [shadow, setShadowShown] = React.useState({
    isShadowShown: false,
    timerStart: null,
  });
  const nextPageKey = pages[currPage].nextPage;
  const prevPageKey = pages[currPage].prevPage;
  const pageContainer = React.useRef();
  const [isRsvpComplete, setRsvpComplete] = React.useState(false);
  const [isMenuOpen, setMenuOpen] = React.useState(false);
  const [isRightClicked, setRightClicked] = React.useState(true);

  // preload images
  React.useEffect(() => {
    for (const imgUrl of imgList) {
      const newImg = new Image();
      newImg.src = imgUrl;
    }
  }, []);

  const maybeSetShadow = () => {
    setShadowShown((prevState) => ({ ...prevState, timerStart: true }));

    setTimeout(() => {
      setShadowShown((prevState) => {
        if (!prevState.isShadowShown && prevState.timerStart !== null) {
          return {
            ...prevState,
            isShadowShown: true,
          };
        }
        return prevState;
      });
    }, 2000);
  };

  useKey("ArrowRight", maybeSetShadow);

  useKey("ArrowLeft", maybeSetShadow);

  useKey(
    "ArrowLeft",
    () => {
      if (prevPageKey && currPage !== "login") {
        setRightClicked(false);
        setPage(prevPageKey);
        setShadowShown({ isShadowShown: false, timerStart: null });
      }
    },
    { event: "keyup" },
    [prevPageKey, currPage]
  );

  useKey(
    "ArrowRight",
    () => {
      if (nextPageKey && currPage !== "login") {
        setRightClicked(true);
        setPage(nextPageKey);
        setShadowShown({ isShadowShown: false, timerStart: null });
      }
    },
    { event: "keyup" },
    [(nextPageKey, currPage)]
  );

  const handleShadowChange = (isShadowShown) =>
    setShadowShown({ isShadowShown, timerStart: null });

  const handleArrowLeave = () => {
    setShadowShown({ ...shadow, timerStart: null });
  };

  // For loading a previously logged in user from browser storage
  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      if (foundUser["time"]) {
        const min = 1000 * 60 * 60;
        const minago = Date.now() - min;
        if (foundUser["time"] < minago) {
          //delete localstorage if the user has been logged in too long
          localStorage.removeItem("user");
        } else {
          setUser(foundUser);
          setPage("home");
        }
      }
    }
  }, []);

  const { isShadowShown } = shadow;

  return (
    <Box sx={{ height: "100%", width: "100%", 'overflow-x': "hidden", position: 'relative' }}>
      <UserContext.Provider value={user}>
        <Box
          className="App"
          flexDirection="column"
          height="100%"
          width="100%"
          ref={pageContainer}
          backgroundColor="#F6EBDE"
        >
          {/* Navigation */}
          {user?.locations?.length ? (
            <React.Fragment>
              <IconButton
                sx={{
                  position: "fixed",
                  top: defaultTheme.spacing(2),
                  left: defaultTheme.spacing(5),
                  mt: 3,
                  mb: 2,
                  zIndex: 10,
                  backgroundColor: ["registry", "travel"].includes(currPage)
                    ? alpha("#000", 0.05)
                    : alpha(defaultTheme.palette.secondary.main, 0.6),
                  "&:hover": {
                    backgroundColor: ["registry", "travel"].includes(currPage)
                      ? alpha("#000", 0.15)
                      : alpha(defaultTheme.palette.secondary.main, 0.8),
                    animation: "bounceDown 500ms ease",
                    "@keyframes bounceDown": {
                      "0%": { transform: "translateY(0)" },
                      "40%": { transform: "translateY(8px)" },
                      "60%": { transform: "translateY(3px)" },
                      "100%": { transform: "translateY(0)" },
                    },
                  },
                }}
                onClick={() => setMenuOpen(!isMenuOpen)}
              >
                <KeyboardDoubleArrowDownRounded
                  sx={{ color: alpha(defaultTheme.palette.primary.main, 0.9) }}
                />
              </IconButton>
              <SwipeableDrawer
                anchor="top"
                open={isMenuOpen}
                onClose={() => {
                  setMenuOpen(false);
                }}
                onOpen={() => {
                  setMenuOpen(true);
                }}
                sx={{
                  ".MuiPaper-root": {
                    backgroundColor: defaultTheme.palette.secondary.main,
                    maxWidth: 200,
                  },
                }}
              >
                <List>
                  <StyledListButton
                    isCurrPage={currPage === "home"}
                    onClick={() => {
                      setPage("home");
                      setMenuOpen(false);
                    }}
                  >
                    <ListItemIcon>
                      <HomeRounded />
                    </ListItemIcon>
                    <Typography my={1} mr={2}>
                      home
                    </Typography>
                  </StyledListButton>
                  <StyledListButton
                    isCurrPage={currPage === "schedule"}
                    onClick={() => {
                      setPage("schedule");
                      setMenuOpen(false);
                    }}
                  >
                    <ListItemIcon>
                      <EventNoteRounded />
                    </ListItemIcon>
                    <Typography my={1} mr={2}>
                      schedule
                    </Typography>
                  </StyledListButton>
                  <StyledListButton
                    isCurrPage={currPage === "rsvp"}
                    onClick={() => {
                      setPage("rsvp");
                      setMenuOpen(false);
                    }}
                  >
                    <ListItemIcon>
                      <DraftsRounded />
                    </ListItemIcon>
                    <Typography my={1} mr={2}>
                      rsvp
                    </Typography>
                  </StyledListButton>
                  <StyledListButton
                    isCurrPage={currPage === "travel"}
                    onClick={() => {
                      setPage("travel");
                      setMenuOpen(false);
                    }}
                  >
                    <ListItemIcon>
                      <FlightTakeoffRounded />
                    </ListItemIcon>
                    <Typography my={1} mr={2}>
                      travel
                    </Typography>
                  </StyledListButton>
                  <StyledListButton
                    isCurrPage={currPage === "thingstodo"}
                    onClick={() => {
                      setPage("thingstodo");
                      setMenuOpen(false);
                    }}
                  >
                    <ListItemIcon>
                      <AttractionsRounded />
                    </ListItemIcon>
                    <Typography my={1} mr={2}>
                      activities
                    </Typography>
                  </StyledListButton>
                  <StyledListButton
                    isCurrPage={currPage === "registry"}
                    onClick={() => {
                      setPage("registry");
                      setMenuOpen(false);
                    }}
                  >
                    <ListItemIcon>
                      <CardGiftcardRounded />
                    </ListItemIcon>
                    <Typography my={1} mr={2}>
                      registry
                    </Typography>
                  </StyledListButton>
                  <StyledListButton
                    isCurrPage={currPage === "gallery"}
                    onClick={() => {
                      setPage("gallery");
                      setMenuOpen(false);
                    }}
                  >
                    <ListItemIcon>
                      <CollectionsRounded />
                    </ListItemIcon>
                    <Typography my={1} mr={2}>
                      gallery
                    </Typography>
                  </StyledListButton>
                </List>
              </SwipeableDrawer>
            </React.Fragment>
          ) : null}
          {nextPageKey && currPage !== "login" ? (
            <Box
              position="fixed"
              top="50%"
              right={0}
              zIndex={10}
              marginRight={7.5}
              sx={{
                [defaultTheme.breakpoints.down("sm")]: { display: "none" },
              }}
            >
              <NextPageArrow
                onMouseEnter={maybeSetShadow}
                onMouseLeave={handleArrowLeave}
                onClick={() => {
                  setRightClicked(true);
                  setPage(nextPageKey);
                  setShadowShown({ ...shadow, isShadowShown: false });

                  if (nextPageKey !== "seeyousoon") {
                    maybeSetShadow();
                  }
                }}
                onShadowChange={handleShadowChange}
                isOnLight={
                  currPage === "rsvp"
                    ? !isRsvpComplete
                    : ["schedule", "thingstodo"].includes(currPage)
                }
              />
            </Box>
          ) : null}
          {prevPageKey ? (
            <Box
              position="fixed"
              top="50%"
              left={0}
              zIndex={10}
              marginLeft={7.5}
              sx={{
                [defaultTheme.breakpoints.down("sm")]: { display: "none" },
              }}
            >
              <PrevPageArrow
                onMouseEnter={maybeSetShadow}
                onMouseLeave={handleArrowLeave}
                onClick={() => {
                  setRightClicked(false);
                  setPage(prevPageKey);
                  setShadowShown({ ...shadow, isShadowShown: false });
                  if (prevPageKey !== "home") {
                    maybeSetShadow();
                  }
                }}
                onShadowChange={handleShadowChange}
                isOnLight={["registry", "travel"].includes(currPage)}
              />
            </Box>
          ) : null}
          <Borders>
            {/* Login */}
            {currPage === "login" ? (
              <Slide
                in={!user?.locations?.length}
                orientation="horizontal"
                direction={"left"}
                easing={{
                  enter: defaultTheme.transitions.easing.easeIn,
                  exit: defaultTheme.transitions.easing.easeOut,
                }}
              >
                <Box height="100%" width="100%" position="absolute">
                  <Login setUser={setUser} onNext={() => setPage(nextPageKey)} />
                </Box>
              </Slide>
            ) : null}
            {/* Pages */}
            {user?.locations?.length ? (
              <>
                <Slide
                  in={currPage === "home"}
                  orientation="horizontal"
                  direction={'right'}
                  easing={{
                    enter: defaultTheme.transitions.easing.easeIn,
                    exit: defaultTheme.transitions.easing.easeIn,
                  }}
                >
                  <Box
                    height="100%"
                    width="100%"
                    position="absolute"
                    // zIndex={currPage === "home" ? 5 : 0}
                  >
                    <Home isShadowShown={isShadowShown} />
                  </Box>
                </Slide>
                <Slide
                  in={currPage === "schedule"}
                  orientation="horizontal"
                  direction={
                    isRightClicked && currPage === 'schedule' ? 'left' :
                    isRightClicked && currPage === 'rsvp' ? 'right' :
                    !isRightClicked && currPage === 'schedule' ? 'right' :
                    'left'
                  }
                  easing={{
                    enter: defaultTheme.transitions.easing.easeIn,
                    exit: defaultTheme.transitions.easing.easeOut,
                  }}
                >
                  <Box
                    height="100%"
                    width="100%"
                    position="absolute"
                    // zIndex={currPage === "schedule" ? 5 : 0}
                  >
                    <Schedule isShadowShown={isShadowShown} />
                  </Box>
                </Slide>
                <Slide
                  in={currPage === "rsvp"}
                  orientation="horizontal"
                  direction={isRightClicked && currPage === 'rsvp' ? 'left' :
                  isRightClicked && currPage === 'travel' ? 'right' :
                  !isRightClicked && currPage === 'rsvp' ? 'right' :
                  'left'}
                  easing={{
                    enter: defaultTheme.transitions.easing.easeIn,
                    exit: defaultTheme.transitions.easing.easeOut,
                  }}
                >
                  <Box
                    height="100%"
                    width="100%"
                    position="absolute"
                    // sx={{opacity: currPage !== "rsvp" ? 0 : 'initial'}}
                    // zIndex={currPage === "rsvp" ? 5 : 0}
                  >
                    <Rsvp
                      isComplete={isRsvpComplete}
                      setComplete={setRsvpComplete}
                      isShadowShown={isShadowShown}
                    />
                  </Box>
                </Slide>
                <Slide
                  in={currPage === "travel"}
                  orientation="horizontal"
                  direction={isRightClicked && currPage === 'travel' ? 'left' :
                  isRightClicked && currPage === 'thingstodo' ? 'right' :
                  !isRightClicked && currPage === 'travel' ? 'right' :
                  'left'}
                  easing={{
                    enter: defaultTheme.transitions.easing.easeIn,
                    exit: defaultTheme.transitions.easing.easeOut,
                  }}
                >
                  <Box
                    height="100%"
                    width="100%"
                    position="absolute"
                    // sx={{opacity: currPage !== "travel" ? 0 : 'initial'}}
                    // zIndex={currPage === "travel" ? 5 : 0}
                  >
                    <Travel isShadowShown={isShadowShown} />
                  </Box>
                </Slide>
                <Slide
                  in={currPage === "thingstodo"}
                  orientation="horizontal"
                  direction={isRightClicked && currPage === 'thingstodo' ? 'left' :
                  isRightClicked && currPage === 'registry' ? 'right' :
                  !isRightClicked && currPage === 'thingstodo' ? 'right' :
                  'left'}
                  easing={{
                    enter: defaultTheme.transitions.easing.easeIn,
                    exit: defaultTheme.transitions.easing.easeOut,
                  }}
                >
                  <Box
                    height="100%"
                    width="100%"
                    position="absolute"
                    // sx={{opacity: currPage !== "thingstodo" ? 0 : 'initial'}}
                    // zIndex={currPage === "thingstodo" ? 5 : 0}
                  >
                    <ThingsToDo isShadowShown={isShadowShown} />
                  </Box>
                </Slide>
                <Slide
                  in={currPage === "registry"}
                  orientation="horizontal"
                  direction={isRightClicked && currPage === 'registry' ? 'left' :
                  isRightClicked && currPage === 'gallery' ? 'right' :
                  !isRightClicked && currPage === 'registry' ? 'right' :
                  'left'}
                  easing={{
                    enter: defaultTheme.transitions.easing.easeIn,
                    exit: defaultTheme.transitions.easing.easeOut,
                  }}
                >
                  <Box
                    height="100%"
                    width="100%"
                    position="absolute"
                    // sx={{opacity: currPage !== "registry" ? 0 : 'initial'}}
                    // zIndex={currPage === "registry" ? 5 : 0}
                  >
                    <Registry isShadowShown={isShadowShown} />
                  </Box>
                </Slide>
                <Slide
                  in={currPage === "gallery"}
                  orientation="horizontal"
                  direction={isRightClicked && currPage === 'gallery' ? 'left' :
                  isRightClicked && currPage === 'seeyousoon' ? 'right' :
                  !isRightClicked && currPage === 'gallery' ? 'right' :
                  'left'}
                  easing={{
                    enter: defaultTheme.transitions.easing.easeIn,
                    exit: defaultTheme.transitions.easing.easeOut,
                  }}
                >
                  <Box
                    height="100%"
                    width="100%"
                    position="absolute"
                    // sx={{opacity: currPage !== "gallery" ? 0 : 'initial'}}
                    // zIndex={currPage === "gallery" ? 5 : 0}
                  >
                    <Gallery
                      isPageShown={currPage === "gallery"}
                      isShadowShown={isShadowShown}
                    />
                  </Box>
                </Slide>
                <Slide
                  in={currPage === "seeyousoon"}
                  orientation="horizontal"
                  direction={"left"}
                  easing={{
                    enter: defaultTheme.transitions.easing.easeIn,
                    exit: defaultTheme.transitions.easing.easeOut,
                  }}
                >
                  <Box
                    height="100%"
                    width="100%"
                    position="absolute"
                    // sx={{opacity: currPage !== "seeyousoon" ? 0 : 'initial'}}
                    // zIndex={currPage === "seeyousoon" ? 5 : 0}
                  >
                    <SeeYouSoon isShadowShown={isShadowShown} />
                  </Box>
                </Slide>
              </>
            ) : null}
          </Borders>
        </Box>
      </UserContext.Provider>
    </Box>
  );
}

export default App;
