import * as React from "react";
import {
  Box,
  IconButton,
  Container,
  TextField,
  Typography,
  SvgIcon,
} from "@mui/material";
import { alpha } from "@mui/material";
import { defaultTheme } from "../theme.tsx";
import { ReactComponent as NextIcon } from "../icons/next_icon.svg";
import { ReactComponent as BoxBorder } from "../icons/box_border.svg";
import Slide from "@mui/material/Slide";
import { guests } from "./Guests.ts";

export const Login = ({ setUser, onNext }) => {
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState("");
  const [isLoginBoxOpen, setLoginBoxOpen] = React.useState(true);
  const formContainer = React.useRef();

  const handleSubmit = (event) => {
    setLoginBoxOpen(false);

    setTimeout(() => {
      setIsSubmitted(true);
    }, 750);

    event?.preventDefault();
    setTimeout(() => {
      const email = userEmail.toLowerCase();
      if (email in guests) {
        setUser(guests[email]);
        guests[email]["time"] = Date.now();
        localStorage.setItem("user", JSON.stringify(guests[email]));
        onNext();
      } else {
        setLoginBoxOpen(true);
        setUserEmail("");
        setErrorMsg("Please use the email with your invitation.");
      }
    }, 1000);
  };

  return (
    <Box position="relative" height="100%" width="100%">
      <Box
        sx={{
          height: "100%",
          width: "100%",
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/entrance_compressed.jpg)`,
          backgroundPosition: "center",
          backgroundSize: "100% auto",
          backgroundRepeat: "no-repeat",
          position: "absolute",
          top: 0,
          left: 0,
          visibility: isSubmitted ? "hidden" : "visible",
        }}
      />
      <Box
        sx={{
          height: "100%",
          width: "100%",
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/entrance_shadow_compressed.jpg)`,
          backgroundPosition: "center",
          backgroundSize: "100% auto",
          backgroundRepeat: "no-repeat",
          position: "absolute",
          visibility: isSubmitted ? "visible" : "hidden",
          top: 0,
          left: 0,
        }}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
          position: "absolute",
        }}
      >
        {/* <Collapse in={isLoginBoxOpen} orientation="horizontal"> */}
        <Container component="main" maxWidth="xs" padding={0}>
          <Box ref={formContainer} overflow="hidden">
            {/* Sign in form */}
            <Slide
              easing={{
                enter: defaultTheme.transitions.easing.easeOut,
                exit: defaultTheme.transitions.easing.easeIn,
              }}
              direction="right"
              in={isLoginBoxOpen}
              container={formContainer.current}
            >
              <Box
                sx={{ paddingX: 2, paddingY: 2, borderRadius: "3px" }}
                bgcolor={alpha(defaultTheme.palette.secondary.main, 0.7)}
              >
                <Box
                  sx={{
                    transform: "rotate(180deg)",
                    marginBottom: defaultTheme.spacing(2),
                    ".boxBorder": {
                      width: "100%",
                      height: "30px",
                    },
                  }}
                >
                  <BoxBorder className="boxBorder" />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    "& input": {
                      backgroundColor: alpha(
                        defaultTheme.palette.secondary.main,
                        0.75
                      ),
                      borderRadius: "4px",
                    },
                  }}
                >
                  <TextField
                    margin="normal"
                    error={!!errorMsg}
                    helperText={errorMsg}
                    id="email"
                    label="Email Address"
                    name="email"
                    onChange={(e) => {
                      const value = e.target.value;
                      setUserEmail(value);
                    }}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit();
                      }
                    }}
                    autoFocus
                    color="text"
                    className="input"
                    sx={{
                      mr: 1,
                      flexGrow: 1,
                    }}
                    value={userEmail}
                  />
                  <Box
                    onClick={handleSubmit}
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                      "&:hover .buttonContainer": {
                        backgroundColor: alpha("#000000", 0.075),
                        ".submit rect": {
                          stroke: defaultTheme.palette.text.primary,
                          fill: defaultTheme.palette.text.primary,
                          transition: "all 250ms",
                        },
                        ".submit circle": {
                          stroke: defaultTheme.palette.text.primary,
                          strokeOpacity: 0,
                          // transition: 'stroke-opacity 200ms'
                        },
                        animation: "bounce 500ms ease",
                        "@keyframes bounce": {
                          "0%": { transform: "translateX(0)" },
                          "40%": { transform: "translateX(6px)" },
                          "60%": { transform: "translateX(3px)" },
                          "100%": { transform: "translateX(0)" },
                        },
                      },
                    }}
                  >
                    <IconButton
                      className="buttonContainer"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      <SvgIcon fontSize="large" color="primary">
                        <NextIcon className="submit" height={24} width={24} />
                      </SvgIcon>
                    </IconButton>
                  </Box>
                </Box>
                <Typography variant="subtitle1" mt={2}>
                  stephanie: "i ran to get you, and you know i'm allergic to
                  running."
                  <br />
                  michael: "that's how i knew it was true love."
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    marginTop: defaultTheme.spacing(2),
                    ".boxBorder": {
                      width: "100%",
                      height: "30px",
                    },
                  }}
                >
                  <BoxBorder className="boxBorder" />
                </Box>
              </Box>
            </Slide>
          </Box>
        </Container>
        {/* </Collapse> */}
      </Box>
    </Box>
  );
};
