import React from "react";
import { Box, Typography } from "@mui/material";
import { DualPageLayout } from "./PageLayout.tsx";
import Link from "@mui/material/Link";

export const ThingsToDo = ({ isShadowShown }) => {
  return (
    <DualPageLayout
      imgFileName={"thingstodo_compressed.jpg"}
      shadowImgFileName="thingstodo_shadow_compressed.jpg"
      isContentLeft={false}
      isShadowShown={isShadowShown}
      title="things to do"
      quote={
        <Typography variant="subtitle2">
          stephanie: i like to make you food. it is my love language.
          <br />
          michael: and accepting it is my love language. we all love in
          different ways.
        </Typography>
      }
    >
      <Box width="100%" height="100%" textAlign="left">
        <Typography>
          check out a list of our favorite restaurants and activities{" "}
          <Link
            target="_blank"
            href={"https://maps.app.goo.gl/LH8eYMMvgh2wJrgg6"}
          >
            here
          </Link>
          . reference notes are included in the descriptions of each location.
        </Typography>
        <Typography mt={1}>
          i have to give a special mention to the georgia aquarium. it's
          massive. if you google "best aqariums in the world," it will show up
          in almost every list.
        </Typography>
        <Typography mt={1}>
          there are also plenty of hiking trails around. you can even kayak/tube
          down the chattahoochee river. just note that car is the most
          convenient mode of transportation for most things.
        </Typography>
      </Box>
    </DualPageLayout>
  );
};
