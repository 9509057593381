import React from "react";
import { Box, CssBaseline, Divider, Typography } from "@mui/material";
import { alpha } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { defaultTheme } from "./theme.tsx";


export const Corner = () => <img src={`${process.env.PUBLIC_URL}/corner_border.svg`} width={42} height={48} />
export const QuoteBorder = () => <img src={`${process.env.PUBLIC_URL}/quote_border.svg`} width={180} height={56} />

export const Borders = ({ children }) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      {/* Borders */}
      <Box
        sx={{
          height: "100%",
          width: defaultTheme.spacing(4),
          position: "fixed",
          backgroundColor: alpha("#EEE7DF", 0.3),
          zIndex: 100,
          [defaultTheme.breakpoints.down("sm")]: {backgroundColor: "#EEE7DF"}
        }}
      />
      <Box
        sx={{
          width: "100%",
          height: defaultTheme.spacing(4),
          position: "fixed",
          backgroundColor: alpha("#EEE7DF", 0.3),
          zIndex: 100,
          [defaultTheme.breakpoints.down("sm")]: {backgroundColor: "#EEE7DF"}

        }}
      />
      <Box
        sx={{
          width: "100%",
          height: defaultTheme.spacing(4),
          position: "fixed",
          backgroundColor: alpha("#EEE7DF", 0.3),
          bottom: 0,
          zIndex: 100,
          [defaultTheme.breakpoints.down("sm")]: {backgroundColor: "#EEE7DF"}
        }}
      />
      <Box
        sx={{
          height: "100%",
          width: defaultTheme.spacing(4),
          position: "fixed",
          backgroundColor: alpha("#EEE7DF", 0.3),
          right: 0,
          zIndex: 100,
          [defaultTheme.breakpoints.down("sm")]: {backgroundColor: "#EEE7DF"}
        }}
      />
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export const ContentHeader = ({ title, quote }) => {
  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h1">{title}</Typography>
      <QuoteBorder />
      {quote}
      <Divider component="div" sx={{width: '75%', my: 2, borderColor: alpha('#000', 0.2)}} />
    </Box>
  );
};

export const SinglePageLayout = ({
  isTextNarrow,
  title,
  quote,
  imgFileName,
  isShown,
  shadowImgFileName,
  isShadowShown,
  textPosition = "center",
  children,
}) => {
  return (
    <Box
      top={24}
      bottom={24}
      left={24}
      right={24}
      display="flex"
      position="absolute"

      sx={{
        backgroundColor: alpha("#F6EBDE", 1),
        backgroundRepeat: "repeat",
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/noise.png)`,
      }}>
      {children ?
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          py={14}
          zIndex={2}
          alignItems="center"
          sx={{
            marginLeft: textPosition === "flex-start" ? 8 : 0,
            marginRight: textPosition === "flex-start" ? 8 : 0,
            maxWidth: isTextNarrow ? 500 : undefined,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            px={3}
            bgcolor={alpha(defaultTheme.palette.secondary.main, 0.85)}
            borderRadius={1}
          >
            <Box mb={5} mt={2} width="100%" display="flex" justifyContent="space-between">
              <Corner />
              <Box sx={{ transform: 'rotate(90deg)' }}>
                <Corner />
              </Box>
            </Box>
            <Typography variant="h1">{title}</Typography>
            <QuoteBorder />
            {quote}
            <QuoteBorder />
            {children}
            <Box mt={5} mb={2} width="100%" display="flex" justifyContent="space-between">
              <Box sx={{ transform: 'rotate(270deg)' }}>
                <Corner />
              </Box>
              <Box sx={{ transform: 'rotate(180deg)' }}>
                <Corner />
              </Box>
            </Box>
          </Box>
        </Box>
        : null
      }
      <Box
        width="100%"
        height="100%"
        sx={{
          position: "absolute",
          visibility: isShown && !isShadowShown ? "visible" : "hidden",
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/${imgFileName})`,
          backgroundPosition: "center",
          backgroundSize: "100% auto",
          backgroundRepeat: "no-repeat",
        }}
      />
      <Box
        width="100%"
        height="100%"
        sx={{
          position: "absolute",
          visibility: isShown && isShadowShown ? "visible" : "hidden",
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/${shadowImgFileName})`,
          backgroundPosition: "center",
          backgroundSize: "100% auto",
          backgroundRepeat: "no-repeat",
        }}
      />
    </Box>
  );
};

export const DualPageLayout = ({
  children,
  isContentLeft,
  imgFileName,
  shadowImgFileName,
  isShown = true,
  title = "",
  quote = "",
  isShadowShown,
}) => {
  const contentHeader = title ? (
    <ContentHeader title={title} quote={quote} />
  ) : null;
  return (
    <Box
      top={24}
      bottom={24}
      left={24}
      right={24}
      height="100%"
      width="100%"
      display="flex"
      visibility={isShown ? "visible" : "hidden"}
      sx={{
        backgroundColor: alpha("#F6EBDE", 1),
        backgroundRepeat: "repeat",
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/noise.png)`,
      }}
    >
      {isContentLeft ? (
        <>
          <Box width="50%" height="100%" position="absolute" left={0} zIndex={1} sx={{
            [defaultTheme.breakpoints.down('sm')]: {
              width: '100%',
              backgroundColor: alpha('#F6EBDE', 0.7)
            }
          }}>
            <Box
              width="100%"
              height="100%"
              position="relative"
              display="flex"
              alignItems="center"
              justifyContent="right"
            >
              <Box
                width="100%"
                height="100%"
                position="absolute"
                overflow="auto"
                right={0}
                py={defaultTheme.spacing(8)}
                px={defaultTheme.spacing(10)}
                sx={{ display: 'flex', flexDirection: 'column' }}
              >
                {contentHeader}
                <Box sx={{ flex: "1 1 auto", overflowY: "auto", paddingRight: 4 }}>
                  {children}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            width="50%"
            top={24}
            bottom={24}
            right={24}
            position="absolute"
            sx={{
              backgroundImage: isShadowShown
                ? `url(${process.env.PUBLIC_URL}/assets/${shadowImgFileName})`
                : `url(${process.env.PUBLIC_URL}/assets/${imgFileName})`,
              backgroundPosition: "center",
              backgroundSize: "100% auto",
              backgroundRepeat: "no-repeat",
              [defaultTheme.breakpoints.down('sm')]: {
                width: '100%',
              }
            }}
          />
        </>
      ) : (
        <>
          <Box width="50%" height="100%" position="absolute" zIndex={1} right={0} sx={{
            [defaultTheme.breakpoints.down('sm')]: {
              width: '100%',
              backgroundColor: alpha('#F6EBDE', 0.7)
            }
          }}>
            <Box
              width="100%"
              height="100%"
              position="relative"
              display="flex"
              alignItems="center"
              justifyContent="right"
            >
              <Box
                width="100%"
                height="100%"
                position="absolute"
                overflow="auto"
                right={0}
                py={defaultTheme.spacing(8)}
                px={defaultTheme.spacing(10)}
                sx={{ display: 'flex', flexDirection: 'column' }}
              >
                {contentHeader}
                <Box sx={{ flex: "1 1 auto", overflowY: "auto", paddingRight: 4 }}>
                  {children}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            width="50%"
            top={24}
            bottom={24}
            left={24}
            position="absolute"
            sx={{
              backgroundImage: isShadowShown
                ? `url(${process.env.PUBLIC_URL}/assets/${shadowImgFileName})`
                : `url(${process.env.PUBLIC_URL}/assets/${imgFileName})`,
              backgroundPosition: "center",
              backgroundSize: "100% auto",
              backgroundRepeat: "no-repeat",
              [defaultTheme.breakpoints.down('sm')]: {
                width: '100%',
              }
            }}
          />
        </>
      )}
    </Box>
  );
};

export const Section = ({ children, title, sx }) => {
  return (
    <Box sx={sx}>
      <Typography variant="h2" my={1}>
        {title}
      </Typography>
      {children}
    </Box>
  );
};
