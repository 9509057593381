import { React, useContext, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import { UserContext } from "./userContext.js";
import { DualPageLayout, SinglePageLayout } from "./PageLayout.tsx";
import { defaultTheme } from "./theme.tsx";

export const Rsvp = ({ isShadowShown, isComplete, setComplete }) => {
  const user = useContext(UserContext);
  // move this to global
  const [isSubmitting, setSubmitting] = useState(false);
  const [initialValues, setInitialValues] = useState({
    email: user["email"],
    numGuests: undefined,
    guests: [],
  });
  const sheetsURL =
    "https://script.google.com/macros/s/AKfycbz8tE2E7YPgO_Fep3gR8o_CVNsia8ShC3uWh8ZRF1JLzMWjtiuRMIukIyhB70PKJ3V5tw/exec";

  const RSVPSchema = Yup.object().shape({
    numGuests: Yup.number()
      .min(1, "number of guests must be at least 1")
      .max(
        user["guestMax"],
        "sorry, you cannot enter more than " + user["guestMax"]
      )
      .round("floor"),
    guests: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("please enter a name"),
        diet: Yup.string().notRequired(),
      })
    ),
    attendingCeremony: Yup.boolean().oneOf([true, false]).required(),
    attendingFamilyMeal: user["atlFamilyDinner"]
      ? Yup.boolean().oneOf([true, false]).required()
      : Yup.boolean().oneOf([true, false]),
    attendingShanghai: user?.locations?.find((element) => element === "cn")
      ? Yup.boolean().oneOf([true, false]).required()
      : Yup.boolean().oneOf([true, false]),
    numShanghaiGuests: Yup.number()
      .min(1, "number of guests must be at least 1")
      .max(
        user["guestMax"],
        "sorry, you cannot enter more than " + user["guestMax"]
      )
      .round("floor"),
    shanghaiGuests: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("please enter a name"),
        diet: Yup.string().notRequired(),
      })
    ),
  });

  return (
    <>
      {isComplete ? (
        <SinglePageLayout
          isTextNarrow
          isShadowShown={isShadowShown}
          isShown={isComplete}
          textPosition="flex-start"
          title="thank you"
          quote={
            <Typography variant="subtitle2">
              stephanie: do you think i could ever beat you in swimming?
              <br />
              michael: no. you'd have to swim twice as fast because you're twice
              as short.
            </Typography>
          }
          imgFileName="thank_you_compressed.jpg"
          shadowImgFileName="thank_you_shadow_compressed.jpg"
        >
          <Box textAlign="left">
            <Box>
              <Typography>
                we got your response! if you want to change or double check
                it&nbsp;
                <Typography
                  onClick={() => setComplete(false)}
                  sx={{
                    textDecoration: "underline",
                    "&:hover": { cursor: "pointer", fontWeight: 600 },
                    display: "inline-block",
                  }}
                >
                  click here.
                </Typography>
              </Typography>
            </Box>
            <Typography mt={1}>
              otherwise, check out some travel options on the next page.
            </Typography>
          </Box>
        </SinglePageLayout>
      ) : (
        <DualPageLayout
          isShown={!isComplete}
          isShadowShown={isShadowShown}
          quote={
            <Typography variant="subtitle2">
              stephanie: are you going to change your last name to mine?
              <br />
              michael: no. my last name comes before yours. i'm a winner; i
              don't come in second.
            </Typography>
          }
          title="rsvp"
          isContentLeft={false}
          imgFileName="rsvp_compressed.jpg"
          shadowImgFileName="rsvp_shadow_compressed.jpg"
        >
          <Typography textAlign="center" my={5} variant="h2">
            please respond by august 21
          </Typography>
          <Formik initialValues={initialValues} validationSchema={RSVPSchema}>
            {({
              handleChange,
              handleBlur,
              touched,
              values,
              errors,
              resetForm,
            }) => (
              <form>
                {user["atlFamilyDinner"] ? (
                  <Box mb={4}>
                    <Typography variant="h2" mb={0} textAlign="left">
                      family dinner
                    </Typography>
                    <Typography mb={-1} textAlign="left" fontWeight={500}>
                      october 4
                    </Typography>
                    <Typography mb={2} textAlign="left" fontWeight={500}>
                      time tbd
                    </Typography>
                    <Box display="flex" justifyContent="space-between" mb={3}>
                      <ButtonGroup>
                        <Button
                          name="attendingFamilyMeal"
                          variant={
                            values.attendingFamilyMeal === true
                              ? "contained"
                              : undefined
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            resetForm({
                              values: {
                                ...values,
                                attendingFamilyMeal: true,
                              },
                              isValidating: true,
                            });
                          }}
                        >
                          Attending
                        </Button>
                        <Button
                          name="attendingFamilyMeal"
                          variant={
                            values.attendingFamilyMeal === false
                              ? "contained"
                              : undefined
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            resetForm({
                              values: {
                                ...values,
                                attendingFamilyMeal: false,
                              },
                              isValidating: true,
                            });
                          }}
                        >
                          Not Attending
                        </Button>
                      </ButtonGroup>
                      <Typography
                        mb={3}
                        mt={2}
                        sx={{
                          [defaultTheme.breakpoints.down("sm")]: {
                            display: "none",
                          },
                        }}
                      >
                        {values.attendingFamilyMeal === true
                          ? "of course i'll be there!"
                          : values.attendingFamilyMeal === false
                          ? "can't make it."
                          : ""}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
                {user?.locations?.find((element) => element === "atl") ? (
                  <Box mb={4}>
                    <Typography variant="h2" mb={0} textAlign="left">
                      atlanta ceremony & reception
                    </Typography>
                    <Typography mb={-1} textAlign="left" fontWeight={500}>
                      october 5
                    </Typography>
                    <Typography mb={2} textAlign="left" fontWeight={500}>
                      2-3pm and 5-10pm
                    </Typography>
                    <Box display="flex" justifyContent="space-between" mb={3}>
                      <ButtonGroup>
                        <Button
                          name="attendingCeremony"
                          variant={
                            values.attendingCeremony === true
                              ? "contained"
                              : undefined
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            resetForm({
                              values: {
                                ...values,
                                attendingCeremony: true,
                                numGuests: undefined,
                                guests: [],
                              },
                              isValidating: true,
                            });
                          }}
                        >
                          Attending
                        </Button>
                        <Button
                          name="attendingCeremony"
                          variant={
                            values.attendingCeremony === false
                              ? "contained"
                              : undefined
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            resetForm({
                              values: {
                                ...values,
                                attendingCeremony: false,
                                numGuests: undefined,
                                guests: [],
                              },
                              isValidating: true,
                            });
                          }}
                        >
                          Not Attending
                        </Button>
                      </ButtonGroup>
                      <Typography
                        mb={3}
                        mt={2}
                        sx={{
                          [defaultTheme.breakpoints.down("sm")]: {
                            display: "none",
                          },
                        }}
                      >
                        {values.attendingCeremony === true
                          ? "wouldn't miss it for the world!"
                          : values.attendingCeremony === false
                          ? "missing it."
                          : ""}
                      </Typography>
                    </Box>
                    {values.attendingCeremony ? (
                      <Stack spacing={2}>
                        <TextField
                          sx={{
                            width: "40%",
                          }}
                          id="numGuests"
                          name="numGuests"
                          type="number"
                          InputProps={{
                            inputProps: {
                              type: "number",
                              min: 1,
                              max: user.guestMax,
                              step: 1,
                            },
                          }}
                          label="number of guests"
                          value={
                            values.numGuests === undefined
                              ? ""
                              : values.numGuests
                          }
                          onChange={(e) => {
                            const val = e.target.value;
                            if (!val.includes(".")) {
                              const newNumberOfGuests = parseInt(val);
                              if (!isNaN(newNumberOfGuests)) {
                                handleChange(e);

                                let newGuests = Array.from(
                                  { length: newNumberOfGuests },
                                  () => ({ name: "", diet: "" })
                                );
                                newGuests.forEach((guest, index) => {
                                  if (values.guests[index]) {
                                    newGuests[index] = values.guests[index];
                                  }
                                });
                                handleChange({
                                  target: { name: "guests", value: newGuests },
                                });
                              }
                            }
                          }}
                          onBlur={handleBlur}
                          error={
                            touched.numGuests &&
                            (!values.numGuests ||
                              values.numGuests > user.guestMax)
                          }
                          helperText={
                            touched.numGuests && !values.numGuests
                              ? "guest count must be at least 1"
                              : touched.numGuests &&
                                values.numGuests > user.guestMax
                              ? `sorry, you cannot enter more than ${user["guestMax"]}`
                              : undefined
                          }
                        />
                        <FieldArray
                          name="guests"
                          render={(arrayHelpers) => (
                            <Stack spacing={2}>
                              {values.guests.map((guest, i) => (
                                <Stack key={i} spacing={2}>
                                  <TextField
                                    sx={{ width: "50%" }}
                                    id={`guests.${i}.name`}
                                    name={`guests.${i}.name`}
                                    label="name"
                                    value={guest.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                      touched.guests &&
                                      touched.guests[i] &&
                                      touched.guests[i].name &&
                                      !!errors.guests &&
                                      !!errors.guests[i] &&
                                      !!errors.guests[i].name
                                    }
                                    helperText={
                                      touched.guests &&
                                      touched.guests[i] &&
                                      touched.guests[i].name &&
                                      errors.guests &&
                                      errors.guests[i] &&
                                      errors.guests[i].name
                                    }
                                  />
                                  <TextField
                                    fullWidth
                                    id={`guests.${i}.diet`}
                                    name={`guests.${i}.diet`}
                                    label={"dietary restrictions"}
                                    value={guest.diet}
                                    onChange={handleChange}
                                  />
                                </Stack>
                              ))}
                            </Stack>
                          )}
                        ></FieldArray>
                      </Stack>
                    ) : null}
                  </Box>
                ) : null}
                {user?.locations?.find((element) => element === "cn") ? (
                  <Box mb={4}>
                    <Typography variant="h2" mb={0} textAlign="left">
                      shanghai family reception
                    </Typography>
                    <Typography mb={-1} textAlign="left" fontWeight={500}>
                      october 19
                    </Typography>
                    <Typography mb={2} textAlign="left" fontWeight={500}>
                      5-9pm
                    </Typography>
                    <Box display="flex" justifyContent="space-between" mb={3}>
                      <ButtonGroup>
                        <Button
                          name="attendingShanghai"
                          variant={
                            values.attendingShanghai === true
                              ? "contained"
                              : undefined
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            resetForm({
                              values: {
                                ...values,
                                attendingShanghai: true,
                                numShanghaiGuests: undefined,
                                shanghaiGuests: [],
                              },
                              isValidating: true,
                            });
                          }}
                        >
                          Attending
                        </Button>
                        <Button
                          name="attendingShanghai"
                          variant={
                            values.attendingShanghai === false
                              ? "contained"
                              : undefined
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            resetForm({
                              values: {
                                ...values,
                                attendingShanghai: false,
                                numShanghaiGuests: undefined,
                                shanghaiGuests: [],
                              },
                              isValidating: true,
                            });
                          }}
                        >
                          Not Attending
                        </Button>
                      </ButtonGroup>
                      <Typography
                        mb={3}
                        mt={2}
                        sx={{
                          [defaultTheme.breakpoints.down("sm")]: {
                            display: "none",
                          },
                        }}
                      >
                        {values.attendingShanghai === true
                          ? "will be there!"
                          : values.attendingShanghai === false
                          ? "won't be there."
                          : ""}
                      </Typography>
                    </Box>
                    {values.attendingShanghai ? (
                      <Stack spacing={2}>
                        <TextField
                          sx={{
                            width: "40%",
                          }}
                          id="numShanghaiGuests"
                          name="numShanghaiGuests"
                          type="number"
                          InputProps={{
                            inputProps: {
                              type: "number",
                              min: 1,
                              max: user.guestMax,
                              step: 1,
                            },
                          }}
                          label="number of guests"
                          value={
                            values.numShanghaiGuests === undefined
                              ? ""
                              : values.numShanghaiGuests
                          }
                          onChange={(e) => {
                            const val = e.target.value;
                            if (!val.includes(".")) {
                              const newNumberOfGuests = parseInt(val);
                              if (!isNaN(newNumberOfGuests)) {
                                handleChange(e);

                                let newGuests = Array.from(
                                  { length: newNumberOfGuests },
                                  () => ({ name: "", diet: "" })
                                );
                                newGuests.forEach((guest, index) => {
                                  if (values.shanghaiGuests[index]) {
                                    newGuests[index] =
                                      values.shanghaiGuests[index];
                                  }
                                });
                                handleChange({
                                  target: {
                                    name: "shanghaiGuests",
                                    value: newGuests,
                                  },
                                });
                              }
                            }
                          }}
                          onBlur={handleBlur}
                          error={
                            touched.numShanghaiGuests &&
                            (!values.numShanghaiGuests ||
                              values.numShanghaiGuests > user.guestMax)
                          }
                          helperText={
                            touched.numShanghaiGuests &&
                            !values.numShanghaiGuests
                              ? "guest count must be at least 1"
                              : touched.numShanghaiGuests &&
                                values.numShanghaiGuests > user.guestMax
                              ? `sorry, you cannot enter more than ${user["guestMax"]}`
                              : undefined
                          }
                        />
                        <FieldArray
                          name="shanghaiGuests"
                          render={(arrayHelpers) => (
                            <Stack spacing={2}>
                              {values.shanghaiGuests.map((shanghaiGuest, i) => (
                                <Stack key={i} spacing={2}>
                                  <TextField
                                    sx={{ width: "50%" }}
                                    id={`shanghaiGuests.${i}.name`}
                                    name={`shanghaiGuests.${i}.name`}
                                    label="name"
                                    value={shanghaiGuest.name}
                                    onChange={handleChange}
                                    error={
                                      touched.shanghaiGuests &&
                                      touched.shanghaiGuests[i] &&
                                      touched.shanghaiGuests[i].name &&
                                      !!errors.shanghaiGuests &&
                                      !!errors.shanghaiGuests[i] &&
                                      !!errors.shanghaiGuests[i].name
                                    }
                                    helperText={
                                      touched.shanghaiGuests &&
                                      touched.shanghaiGuests[i] &&
                                      touched.shanghaiGuests[i].name &&
                                      errors.shanghaiGuests &&
                                      errors.shanghaiGuests[i] &&
                                      errors.shanghaiGuests[i].name
                                    }
                                    onBlur={handleBlur}
                                  />
                                  <TextField
                                    fullWidth
                                    id={`shanghaiGuests.${i}.diet`}
                                    name={`shanghaiGuests.${i}.diet`}
                                    label={"dietary restrictions"}
                                    value={shanghaiGuest.diet}
                                    onChange={handleChange}
                                  />
                                </Stack>
                              ))}
                            </Stack>
                          )}
                        ></FieldArray>
                      </Stack>
                    ) : null}
                  </Box>
                ) : null}
                <Box display="flex" justifyContent="center">
                  <Button
                    disabled={
                      values.attendingCeremony === undefined ||
                      (values.attendingCeremony &&
                        values.numGuests === undefined) ||
                      (values.attendingCeremony && values.numGuests === 0) ||
                      (values.guests?.length &&
                        values.guests.some(({ name }) => !name)) ||
                      (user["atlFamilyDinner"] &&
                        values.attendingFamilyMeal === undefined) ||
                      (user?.locations?.find((element) => element === "cn") &&
                        values.attendingShanghai === undefined) ||
                      (user?.locations?.find((element) => element === "cn") &&
                        values.attendingShanghai &&
                        values.numShanghaiGuests === undefined) ||
                      (user?.locations?.find((element) => element === "cn") &&
                        values.attendingShanghai &&
                        values.numShanghaiGuests === 0) ||
                      (values.shanghaiGuests?.length &&
                        values.shanghaiGuests.some(({ name }) => !name)) ||
                      isSubmitting
                    }
                    color="primary"
                    variant="contained"
                    type="submit"
                    size="large"
                    onClick={() => {
                      setInitialValues(values);
                      setSubmitting(true);
                      return fetch(sheetsURL, {
                        method: "POST",
                        attendingCeremony: null,
                        body: JSON.stringify(values),
                      })
                        .then(() => {
                          setComplete(true);
                        })
                        .finally(() => {
                          setSubmitting(false);
                        });
                    }}
                  >
                    {isSubmitting ? <CircularProgress size={24} /> : "Submit"}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </DualPageLayout>
      )}
    </>
  );
};
