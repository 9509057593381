import React from "react";
import { DualPageLayout } from "./PageLayout.tsx";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const Registry = ({ isShadowShown }) => {
    return (
        <DualPageLayout
            imgFileName={"registry_shadow_compressed.jpg"}
            shadowImgFileName="registry_compressed.jpg"
            isContentLeft
            isShadowShown={isShadowShown}
            title="registry"
            quote={
                <Typography variant="subtitle2">
                    stephanie: would you dress up as santa for your kids?
                    <br />
                    michael: yes. that way they will know that santa is asian.
                </Typography>
            }
        >
            <Box width="100%" height="100%" textAlign="center" mt={-3}>
                <img
                    width="33%"
                    height="auto"
                    src={`${process.env.PUBLIC_URL}/assets/michael_present.gif`}
                />
                <Typography width="100%">
                    your presence is the only present we need.
                </Typography>
            </Box>
        </DualPageLayout>
    )
};
